import dynamic from 'next/dynamic';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import getSearchClient from 'server/repository/algolia/client';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import { TrackingService } from 'service/tracking/trackingService';
import TemplateMapper from 'templates/TemplateMapper';
import type { IGlobalMetaData } from 'types/GlobalMetadata';

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const UpArrow = dynamic(() => import('components/UpArrow').then((m: any) => m.UpArrow), {
  ssr: true,
}) as any;

const ClientSlugPage = ({
  breadcrumbsCMSData,
  data,
  globalMetadata,
  searchOverlayData,
  difficultyCopy,
  recipeCopy,
  searchItemLabels,
}: {
  breadcrumbsCMSData: IBreadcrumbTitles;
  data: any;
  globalMetadata: IGlobalMetaData;
  searchOverlayData: ISearchOverlay;
  recipeCopy: DictionaryItem[];
  difficultyCopy: Record<string, string>;
  searchItemLabels: Record<string, string>;
}) => {
  const page = data.genericPages?.[0] || {};
  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);
  const { theme } = useGlobalContext();

  useEffect(() => {
    const cg1 = page?.contentGroupingTrackingValue;
    const cg2 = page?.contentGroupingTrackingValues;
    if (cg1) {
      if (cg2) {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
          cg2: cg2.replace(/_/g, ' '),
        });
      } else {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  return (
    <>
      {page?.cookieBannerRequired === false && (
        <Script type="application/javascript" id="supress-cookie-banner">
          var UC_UI_SUPPRESS_CMP_DISPLAY=true;
        </Script>
      )}
      <div className="max-w-6xl mx-auto">
        {!theme && (
          <Breadcrumbs isPositionRelative titles={breadcrumbsCMSData} pageName={page.title} />
        )}
        <TemplateMapper
          components={page?.pageComponents}
          copy={page?.dictionary?.dictionaryItems || null}
          recipeCopy={recipeCopy}
          difficultyCopy={difficultyCopy}
          globalMetadata={globalMetadata}
          searchItemLabels={searchItemLabels}
          searchData={searchOverlayDataExtended}
        />
        <UpArrow />
      </div>
    </>
  );
};

export default ClientSlugPage;
